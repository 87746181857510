import * as React from "react";
import { useExperiment } from "@shared-ui/experiment-context";
import { Field } from "@egds/react-core/form";
import { usePreloadPropertySearchMutation } from "./usePreloadPropertySearchMutation";
import { useBexApi } from "@shared-ui/bex-api-context";
import { getPropertySearchCriteriaFromQueryParams } from "@shared-ui/retail-search-tools-product";
import { PrefetchSearchResultsMutationVariables } from "__generated__/typedefs";
import { ClientLogger } from "bernie-client";
import { SystemEvent, SystemEventLevel } from "bernie-logger";

export const useOptimizeLodgingSearchLoadTimeExperiment = (brand?: string) => {
  const { exposure: preSearchExposure, logExperiment: logPreSearchExperiment } = useExperiment(
    "Vrbo_SRP_Pre_Search_From_Home_Page"
  );
  const logger = ClientLogger.getLoggerWithIdentifier("triggerLodgingPreloadingSearch");

  const { context } = useBexApi();
  const { executeMutation } = usePreloadPropertySearchMutation();

  const preloadCallTriggerCount = React.useRef(0);
  const dateChangeCount = React.useRef(0);
  const roomsTravelerChangeCount = React.useRef(0);
  const destinationChangeCount = React.useRef(0);
  const isVrbo = brand === "vrbo";

  const shouldFetchPreloadingSearchResults = React.useMemo(
    () => preloadCallTriggerCount.current < 2 && ((isVrbo && preSearchExposure.bucket === 1) || !isVrbo),
    [preloadCallTriggerCount.current]
  );

  const triggerMutation = async (mutationVariables: PrefetchSearchResultsMutationVariables) => {
    try {
      await executeMutation({ variables: mutationVariables });
    } catch (error) {
      logger.logEvent(
        new SystemEvent(SystemEventLevel.ERROR, "LODGING_PRELOADING_SEARCH_RESULTS_MUTATION_ERROR"),
        error
      );
    }
  };

  const triggerPreloadingSearch = React.useCallback(
    (field: Field, event: React.SyntheticEvent, queryParams: string) => {
      const hasDestinationFieldValue = new URLSearchParams(queryParams).get("destination") !== "";
      const searchCriteria = getPropertySearchCriteriaFromQueryParams(new URLSearchParams(queryParams))
        .propertySearchCriteriaInput;
      const mutationVariables = {
        context: context,
        criteria: searchCriteria,
        shoppingContext: { multiItem: null },
      };

      if (shouldFetchPreloadingSearchResults && hasDestinationFieldValue) {
        if (
          (field?.name === "EGDSDateRangePicker" || field?.name === "EGDSDateRangePicker-Flexible") &&
          dateChangeCount.current === 0
        ) {
          dateChangeCount.current++;
          preloadCallTriggerCount.current++;
          triggerMutation(mutationVariables as PrefetchSearchResultsMutationVariables);
        }
        if (field?.name === "EGDSRoomsTravelerSelector" && roomsTravelerChangeCount.current === 0) {
          roomsTravelerChangeCount.current++;
          preloadCallTriggerCount.current++;
          triggerMutation(mutationVariables as PrefetchSearchResultsMutationVariables);
        }
      }
      try {
        if (hasDestinationFieldValue && isVrbo) {
          logPreSearchExperiment(preSearchExposure);
        }
      } catch {
        // noop
      }
    },
    [dateChangeCount, roomsTravelerChangeCount, destinationChangeCount]
  );

  return {
    triggerPreloadingSearch,
    preloadCallTriggerCount,
    dateChangeCount,
    roomsTravelerChangeCount,
    destinationChangeCount,
  };
};
